import React from "react"
import _map from "lodash/map"

import Storyblok from "@src/utilities/storyblok"

import useStoryblokBridge from "@src/hooks/use-storyblok-bridge"

import getGlobalSiteSettings from "@src/pages/api/get-global-site-settings"
import getPaths from "@src/pages/api/get-page-paths"

import populateCards from "@src/utilities/populate-cards"
import getStoryblokApiKey from "@src/utilities/storyblok-api-key"

import getStoryblokCacheVersion from "@src/workers/storyblok/get-cache-version"

import GeneralLayout from "@src/layouts/general"

import PageBuilder from "@src/components/page-builder"
import SEO from "@src/components/seo"

import type { GetStaticPropsContext, InferGetStaticPropsType } from "next"

export default function Page({
  preview,
  siteSettings,
  story,
  storyblokApiKey,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const blok = useStoryblokBridge(story, storyblokApiKey, preview)

  return (
    <GeneralLayout siteSettings={siteSettings}>
      {blok.content && blok.content.seo && <SEO {...blok.content.seo} />}
      {blok &&
        blok.content &&
        blok.content.body &&
        blok.content.body.map((body: any) => (
          <PageBuilder content={body} key={body._uid} />
        ))}
    </GeneralLayout>
  )
}

export const getStaticPaths = async () => {
  const paths = await getPaths()
  return {
    paths: _map(paths, (slug) => ({
      params: {
        slug: slug.split("/"),
      },
    })),
    fallback: false,
  }
}

export const getStaticProps = async (context: GetStaticPropsContext) => {
  const paramsSlugs = (
    (context.params && (context.params.slug as string[])) ??
    []
  ).filter((slug) => !!slug && slug !== "null" && slug !== "undefined")
  const slugs = paramsSlugs.length ? paramsSlugs : ["home"]

  const apiKey = getStoryblokApiKey(context.preview)
  const cacheVersion = await getStoryblokCacheVersion()

  const [siteSettings, storyRequest] = await Promise.all([
    getGlobalSiteSettings(context.preview ?? false),
    Storyblok(apiKey).get(`cdn/stories/${slugs.join("/")}`, {
      cv: cacheVersion,
      version: context.preview ? "draft" : "published",
    }),
  ])

  const story = await populateCards(storyRequest.data.story)

  return {
    props: {
      preview: context.preview ?? false,
      siteSettings,
      story,
      ...(context.preview && { storyblokApiKey: apiKey }),
    },
  }
}
